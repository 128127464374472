<template>
  <div class="twitch-embed-container">
    <v-btn
      color="purple"
      class="twitch-button"
      href="https://www.twitch.tv/vforce_fn"
      target="_blank"
      dark
      block
    >
      Join live
    </v-btn>
    <iframe
      src="https://player.twitch.tv/?channel=vforce_fn&parent=vforce.gg&autoplay=true&muted=true"
      frameborder="0"
      allowfullscreen="true"
      scrolling="no"
      class="twitch-embed"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'TwitchEmbedComponent',
};
</script>

<style scoped>
.twitch-embed-container {
  position: fixed; /* Changed to fixed for consistency across page changes */
  bottom: -10px;
  right: 0px;
  z-index: 1000;
}
.twitch-embed {
  width: 320px;
  height: 180px;
}
.twitch-button {
  width: 200px;
}
@media screen and (max-width: 768px) {
  .twitch-embed {
    width: 200px; /* Smaller width for mobile */
    height: 113px; /* Maintaining the 16:9 aspect ratio for the smaller width */
  }
}
</style>
