<template>
  <v-app>
    <Navigation v-if="routeName !== 'required-info'" />
    <v-main
      class="px-0 VMainTopPadding"
      :class="[
        isBlackRoute ? 'blackBg' : 'mainBg',
        {
          VMainBottomPadding: shouldAddBottomPadding,
        },
      ]"
    >
      <router-view :key="$route.fullPath" />
    </v-main>
    <TwitchEmbedComponent v-if="shouldHideEmbed" />
    <Footer />
  </v-app>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { fb } from '@/firebase';

import Navigation from '@/_components/others/navigation/_NavigationComponent.vue';
import Footer from '@/_components/others/footer/FooterComponent.vue';
import TwitchEmbedComponent from './_components/others/TwitchEmbedComponent.vue';

export default {
  name: 'App',
  components: { Navigation, Footer, TwitchEmbedComponent },
  data() {
    return {
      blackRoutes: ['/bets'], // Define routes with black background
    };
  },

  computed: {
    ...mapState(['route', 'routeName']),
    ...mapState('UserStore', ['user']),
    isBlackRoute() {
      return this.routeName === 'bets';
    },
    shouldHideEmbed() {
      return (
        this.routeName !== 'store' &&
        this.routeName !== 'settings' &&
        this.routeName !== 'bets'
      );
    },
    shouldAddBottomPadding() {
      // Example condition based on route name
      return this.routeName !== 'settings';
    },
  },

  mounted() {
    fb.auth().onAuthStateChanged(this.handleAuthStateChange);
  },
  methods: {
    ...mapActions('UserStore', ['GET_USER', 'CHECK_USER_COINS']),

    async fetchUserData(user) {
      await this.GET_USER(user);

      this.CHECK_USER_COINS(user);
    },
    async handleAuthStateChange(user) {
      this.$store.commit('UserStore/UPDATE_USER', user);
      if (this.user) {
        await this.fetchUserData(user);
      }
    },
  },
};
</script>
<style>
.VMainTopPadding {
  padding-top: 54px !important;
}
.VMainBottomPadding {
  padding-bottom: 150px !important;
}
.v-application {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.blackBg {
  background-color: black;
}
.v-main {
  flex: 1; /* This ensures v-main grows to fill the available space */
  min-height: 100vh; /* This forces v-main to be at least 100vh */
  display: flex;
  flex-direction: column;
}
.content-wrapper {
  flex: 1; /* This makes the content grow to fill available space */
}

.v-badge__wrapper {
  z-index: 20;
}
.mainBg {
  background-image: linear-gradient(
    109.2deg,
    rgba(107, 52, 255, 1) 16.5%,
    rgb(0, 120, 218) 81%
  );
}
.mainBg1 {
  background-image: linear-gradient(
    109.2deg,
    rgba(107, 52, 255, 1) 16.5%,
    rgb(0, 120, 218) 81%
  );
}
.mainBg2 {
  background-image: linear-gradient(
    109.2deg,
    rgba(107, 52, 255, 1) 16.5%,
    rgba(0, 210, 175, 0.992) 91%
  );
}
.sheetBg {
  background-image: linear-gradient(
    109.2deg,
    rgba(107, 52, 255, 1) 16.5%,
    rgb(0, 120, 218) 81%
  );
}

.navBg {
  background-image: linear-gradient(to right, #6a11cb 0%, #2575fc 50%);
  height: 100%;
}
.margTopHero {
  margin-top: 50px;
}
.padTopHero {
  padding-top: 50px;
}
.separateNavMain {
  margin-top: 100px;
}
.separateSections {
  margin-top: 75px;
}
.separateTitle {
  margin-bottom: 75px;
}
.app {
  background: white;
  color: black;
}
.roundedCard {
  border-radius: 35px !important;
}
.dark {
  background: black;
  color: white;
}
.router-link {
  text-decoration: none;
}
.pointer {
  cursor: pointer;
}

.inactiveOpacity {
  opacity: 0.5;
}
.inactive-card {
  position: relative;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Semi-transparent white background */
}

.inactive-card::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 5px,
    black 5px,
    black 10px
  );
  opacity: 0.2; /* Increased opacity for more pronounced effect */
  pointer-events: none; /* Exclude the background from capturing events */
}

.inactive-text {
  color: #333; /* Darker color for the text */
  font-style: italic; /* Add any additional styling for inactive text */
}

.reverted-styles {
  color: #993333;
  /* Adjust other styles as needed */
}

/* Styles for buttons that should not be affected by the background */
.reverted-styles .unaffected-button {
  background-color: blue !important; /* Use !important to override other styles */
  color: #fff !important; /* Set text color to white for visibility */
  opacity: 1 !important; /* Set opacity to 1 to make it fully opaque */
}
</style>
