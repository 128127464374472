import { db } from '@/firebase';

const state = {
  adminAllTourneys: [],
};

const mutations = {
  // SETTERS
  SET_ADMIN_TOURNEYS(state, payload) {
    state.adminAllTourneys.push(payload);
  },
};

const getters = {};

const actions = {
  async GET_ADMIN_TOURNEYS({ commit, state }) {
    const dataBase = await db
      .collection('tourneysFn')
      .where('status', '==', true);
    const dbResults = await dataBase.get();
    const tourneys = dbResults;
    tourneys.forEach((doc) => {
      if (
        !state.adminAllTourneys.some(
          (tourney) => tourney.userId === doc.data().userId
        )
      ) {
        const data = {
          list: doc.data().list,
          username: doc.data().username,
          userId: doc.data().userId,
        };
        commit('SET_ADMIN_TOURNEYS', data);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
