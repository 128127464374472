import { rtdb } from '@/firebase';

const state = {
  tournamentCredits: [],
  lastTournamentCreditsKey: null,
  tournamentCreditsLoaded: false,
  tournamentCreditsListener: null,
  tournamentCandidates: [],
  lastTournamentCandidatesKey: null,
  tournamentCandidatesListener: null,
  tournamentCandidatesLoaded: false,
  tournamentActivity: [],
  lastActivityKey: null,
  isActivitiesLoaded: false,
  tournamentBets: [],
};

const getters = {
  tournamentBets: (state) => state.tournamentBets,
};

const mutations = {
  UPDATE_TOURNAMENTS_CREDITS(state, { tournamentsCredits, lastKey }) {
    state.tournamentCredits = tournamentsCredits;
    state.lastTournamentCreditsKey = lastKey;
  },

  TOURNAMENTS_CREDITS_LOADED(state) {
    state.tournamentCreditsLoaded = true;
  },

  SET_TOURNAMENTS_CREDITS_LISTENER(state, listener) {
    state.tournamentCreditsListener = listener;
  },
  UPDATE_ACTIVITIES(state, { activities }) {
    state.tournamentActivity = activities; // Assuming you want to append new activities
  },
  UPDATE_LAST_ACTIVITY_KEY(state, key) {
    state.lastActivityKey = key;
  },
  ACTIVITIES_LOADED(state) {
    state.isActivitiesLoaded = true;
  },
  SET_TOURNAMENT_BETS(state, results) {
    state.tournamentBets = results;
  },
  UPDATE_CANDIDATES(state, { candidates, lastKey }) {
    state.tournamentCandidates = candidates;
    state.lastTournamentCandidatesKey = lastKey;
  },

  CANDIDATES_LOADED(state) {
    state.tournamentCandidatesLoaded = true;
  },

  SET_CANDIDATES_LISTENER(state, listener) {
    state.tournamentCandidatesListener = listener;
  },
};
const actions = {
  async GET_RTDB_TOURNAMENT_CREDITS({ commit, state }, tournamentDocId) {
    let tournamentsCreditsRef = rtdb
      .ref('tournamentsCredits')
      .orderByChild('tournamentDocId')
      .equalTo(tournamentDocId);

    if (state.lastCreditsKey) {
      tournamentsCreditsRef = tournamentsCreditsRef.startAfter(
        state.lastCreditsKey
      );
    }

    try {
      // Perform a one-time fetch to get initial or next data
      const snapshot = await tournamentsCreditsRef
        .limitToFirst(5)
        .once('value');
      const tournamentsCredits = [];

      snapshot.forEach((childSnapshot) => {
        const credit = childSnapshot.val();
        if (credit.isActionValid === true) {
          // Filter for valid actions
          tournamentsCredits.push({
            ...credit,
            creditDocId: childSnapshot.key,
          });
        }
      });

      // Update credits and last key in state
      // We need to ensure lastKey reflects a valid credit if any exist
      let lastKey = null;
      if (tournamentsCredits.length > 0) {
        lastKey = tournamentsCredits[tournamentsCredits.length - 1].creditDocId;
      }

      commit('UPDATE_TOURNAMENTS_CREDITS', {
        tournamentsCredits,
        lastKey: lastKey,
      });
      commit('TOURNAMENTS_CREDITS_LOADED');

      // Real-time listener setup if needed
      const unsubscribe = tournamentsCreditsRef.on('value', (snapshot) => {
        const tournamentsCredits = [];
        snapshot.forEach((childSnapshot) => {
          const credit = childSnapshot.val();
          if (credit.isActionValid === true) {
            tournamentsCredits.push({
              ...credit,
              creditDocId: childSnapshot.key,
            });
          }
        });
        let lastKey = null;
        if (tournamentsCredits.length > 0) {
          lastKey =
            tournamentsCredits[tournamentsCredits.length - 1].creditDocId;
        }
        commit('UPDATE_TOURNAMENTS_CREDITS', { tournamentsCredits, lastKey });
      });
      commit('SET_TOURNAMENTS_CREDITS_LISTENER', unsubscribe);
    } catch (error) {
      console.error('Error in fetching credits:', error);
      commit('UPDATE_TOURNAMENTS_CREDITS', {
        tournamentsCredits: [],
        lastKey: null,
      });
      commit('TOURNAMENTS_CREDITS_LOADED');
    }
  },
  async GET_RTDB_TOURNAMENT_ACTIVITY({ commit, state }, tournamentDocId) {
    let activityRef = rtdb.ref('tournamentsActivity');

    try {
      let snapshot;
      if (state.lastActivityKey) {
        // If we have a lastKey in state, we fetch activities starting after that key
        // First, we fetch all activities after the last key, then filter by tournamentDocId
        snapshot = await activityRef
          .orderByKey() // Order by key to use startAfter
          .startAfter(state.lastActivityKey)
          .limitToFirst(10) // Fetch more to account for filtering, adjust this number based on your data
          .once('value');

        // Filter to ensure we only get activities for the correct tournament
        const activities = [];
        snapshot.forEach((child) => {
          const activity = child.val();
          if (activity.tournamentDocId === tournamentDocId) {
            activities.push({
              ...activity,
              activityDocId: child.key,
            });
            if (activities.length === 5) return false; // Stop once we've collected 5 activities
          }
        });

        // Update lastKey to be the last valid activity for this tournament
        const lastActivity = activities[activities.length - 1];
        const newLastKey = lastActivity ? lastActivity.activityDocId : null;

        commit('UPDATE_ACTIVITIES', {
          activities: activities.slice(0, 5), // Ensure we only commit 5 activities
        });
        commit('UPDATE_LAST_ACTIVITY_KEY', newLastKey); // Update lastActivityKey in Vuex
      } else {
        // Initial fetch or fetching all for the tournament
        snapshot = await activityRef
          .orderByChild('tournamentDocId')
          .equalTo(tournamentDocId)
          .limitToFirst(5)
          .once('value');

        let activities = [];
        snapshot.forEach((child) => {
          activities.push({
            ...child.val(),
            activityDocId: child.key,
          });
        });

        const lastActivity = activities[activities.length - 1];
        commit('UPDATE_ACTIVITIES', {
          activities,
        });
        commit(
          'UPDATE_LAST_ACTIVITY_KEY',
          lastActivity ? lastActivity.activityDocId : null
        );
      }
      commit('ACTIVITIES_LOADED');
    } catch (error) {
      console.error('Error in fetching activities:', error);
      commit('UPDATE_ACTIVITIES', { activities: [] });
      commit('UPDATE_LAST_ACTIVITY_KEY', null);
      commit('ACTIVITIES_LOADED');
    }
  },

  async GET_RTDB_TOURNAMENT_CANDIDATES({ commit, state }, tournamentDocId) {
    let candidatesRef = rtdb
      .ref('tournamentsCandidates')
      .orderByChild('tournamentDocId')
      .equalTo(tournamentDocId);

    if (state.lastCandidatesKey) {
      candidatesRef = candidatesRef.startAfter(state.lastCandidatesKey);
    }

    try {
      // Perform a one-time fetch to get initial or next data
      const snapshot = await candidatesRef.limitToFirst(5).once('value');
      const candidates = [];

      snapshot.forEach((childSnapshot) => {
        const candidate = childSnapshot.val();
        if (candidate.isActionValid === true) {
          // Filter for valid actions
          candidates.push({
            ...candidate,
            candidateDocId: childSnapshot.key,
          });
        }
      });

      // Update candidates and last key in state
      // We need to ensure lastKey reflects a valid candidate if any exist
      let lastKey = null;
      if (candidates.length > 0) {
        lastKey = candidates[candidates.length - 1].candidateDocId;
      }

      commit('UPDATE_CANDIDATES', {
        candidates,
        lastKey: lastKey,
      });
      commit('CANDIDATES_LOADED');

      // Real-time listener setup if needed
      const unsubscribe = candidatesRef.on('value', (snapshot) => {
        const candidates = [];
        snapshot.forEach((childSnapshot) => {
          const candidate = childSnapshot.val();
          if (candidate.isActionValid === true) {
            candidates.push({
              ...candidate,
              candidateDocId: childSnapshot.key,
            });
          }
        });
        let lastKey = null;
        if (candidates.length > 0) {
          lastKey = candidates[candidates.length - 1].candidateDocId;
        }
        commit('UPDATE_CANDIDATES', { candidates, lastKey });
      });
      commit('SET_CANDIDATES_LISTENER', unsubscribe);
    } catch (error) {
      console.error('Error in fetching candidates:', error);
      commit('UPDATE_CANDIDATES', { candidates: [], lastKey: null });
      commit('CANDIDATES_LOADED');
    }
  },
  GET_TOURNAMENT_BETS({ commit }, tournamentDocId) {
    const ref = rtdb.ref(`/tournamentsMoney/${tournamentDocId}`);

    // Set up a listener for value changes
    const unsubscribe = ref.on('value', (snapshot) => {
      if (!snapshot.exists()) {
        console.warn(`No bets found for tournament: ${tournamentDocId}`);
        commit('SET_TOURNAMENT_BETS', []);
      } else {
        commit('SET_TOURNAMENT_BETS', snapshot.val());
      }
    });

    // Return the unsubscribe function so it can be called if needed
    return () => {
      unsubscribe();
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
