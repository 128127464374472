import { rtdb } from '@/firebase';

const state = {
  accountTweetsRequests: [],

  // LOADERS
  accountTweetsRequestsLoaded: false,
};

const mutations = {
  SET_ACCOUNT_TWEETS_REQUESTS(state, payload) {
    if (payload.requests) {
      state.accountTweetsRequests.push(payload);
    } else {
      state.accountTweetsRequests = [];
    }
  },
  // LOADERS
  ACCOUNT_TWEETS_REQUESTS_LOADED(state) {
    state.accountTweetsRequestsLoaded = true;
  },
};

const getters = {};

const actions = {
  async GET_ACCOUNT_TWEETS_REQUESTS({ commit }, userId) {
    const usersRef = await rtdb.ref('/newRequests');
    usersRef
      .orderByChild('userId')
      .equalTo(userId)
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const data = childSnapshot.val();
          commit('SET_ACCOUNT_TWEETS_REQUESTS', data);
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    commit('ACCOUNT_TWEETS_REQUESTS_LOADED');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
