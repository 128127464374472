import { db, rtdb } from '@/firebase';
import router from '../../router/router';
import _ from 'lodash';

const state = {
  // FIRESTORE SETTERS
  accountData: {},
  accountUserId: null,
  accountUsername: null,
  accountMainGame: null,
  accountLastActiveTourneyMonth: null,
  accountLastActiveTourneyDate: null,
  accountMonthlyTourneys: [],
  accounts: [],
  accountExp: {},
  accountExpTweets: [],
  accountExpTourneys: [],
  accountTourneyApplications: [],
  accountClips: [],
  accountTourneysCooldown: [],
  accountTourneys: [],
  accountMerch: [],
  accountRanks: [],
  loadedTourneyTotals: [],
  accountAllGiveaways: [],
  accountActiveGiveaways: [],
  accountGiveawaysCooldown: null,
  accountFortniteBuildsLevel: null,
  profileAccountExp: null,
  // FIRESTORE LOADERS
  accountTourneysLoaded: false,
  accountMonthlyTourneysLoaded: false,
  accountTourneyLoaded: false,
  accountLoaded: false,
  accountsLoaded: false,
  tourneyBettingsTotalLoaded: false,
  accountExpLoaded: false,
  accountRanksLoaded: false,
  accountGiveawaysLoaded: false,
  archivedAccountTourneys: [],
  archivedAccountTourneysLoaded: false,

  // RTDB SETTERS
  rtdbTourneyParticipants: [],
  // RTDB LOADERS
  rtdbTourneyLoaded: false,
  rtdbTourneyBriefLoaded: false,
  rtdbMonthlyTourneyBriefLoaded: false,

  // NOT USED
  accountAllTourneys: [],
};

const mutations = {
  // Set mutations
  SET_ACCOUNTS(state, payload) {
    state.accounts.push(payload);
  },
  SET_ACCOUNT(state, payload) {
    state.accountData = payload.data();
    state.accountUserId = payload.data().userId;

    state.accountSubscription = payload.data().subscription;
  },

  SET_ACCOUNT_TOURNEY(state, payload) {
    state.accountTourney = payload;
  },
  SET_ACCOUNT_TOURNEYS_PROFILE(state, payload) {
    if (payload && payload.clips) {
      state.accountClips = payload.clips;
    }
    if (payload.cooldown) {
      state.accountTourneysCooldown = payload.cooldown;
    }
  },

  SET_ACCOUNT_RANKS(state, payload) {
    let data = payload.data();
    state.accountRanks = data;
    if (data && data.fortniteBuildsLevel) {
      state.accountFortniteBuildsLevel = data.fortniteBuildsLevel;
    }
  },
  RESET_ACCOUNT_TOURNEYS(state) {
    state.accountTourneys = [];
    state.accountExpTourneys = [];
    state.accountTourneyApplications = [];
  },
  RESET_ACCOUNT_MONTHLY_TOURNEYS(state) {
    state.accountMonthlyTourneys = [];
  },

  ADD_LOADED_TOURNEY_TOTAL(state, docId) {
    // Check if the docId is not already in the array
    if (!state.loadedTourneyTotals.includes(docId)) {
      // Add the docId to the array
      state.loadedTourneyTotals.push(docId);
    }
  },
  SET_ACCOUNT_VISITED_TOURNEYS(state, payload) {
    const accountTourneysIndex = state.accountsTourneysVisited.findIndex(
      (item) => {
        return item.userId === payload.userId;
      }
    );
    if (accountTourneysIndex !== -1) {
      state.accountTourneys =
        state.accountsTourneysVisited[accountTourneysIndex];
    } else {
      return;
    }
  },
  SET_ACCOUNT_TOURNEYS(state, payload) {
    state.accountTourneys = payload;
    state.accountTourneys = _.orderBy(
      state.accountTourneys,
      ['active'],
      ['desc']
    );
  },
  SET_ACCOUNT_MONTHLY_TOURNEYS(state, payload) {
    let containsCoins = false;
    let containsPrizes = false;
    if (payload.winnersCoins && payload.winnersCoins.length) {
      containsCoins = payload.winnersCoins.some(
        (item) => item.username === state.accountUsername
      );
    }
    if (payload.winnersPrizes && payload.winnersPrizes.length) {
      containsPrizes = payload.winnersPrizes.some(
        (item) => item.username === state.accountUsername
      );
    }

    if (containsCoins || containsPrizes) {
      payload.claimRewards = true;
    } else {
      payload.claimRewards = false;
    }
    state.accountMonthlyTourneys.push(payload);
  },

  SET_ACCOUNT_MERCH(state, payload) {
    state.accountMerch = payload.data();
  },
  SET_ACCOUNT_EXP_TWEETS(state, payload) {
    state.accountExpTweets.push(payload);
  },
  SET_ACCOUNT_EXP(state, payload) {
    state.accountExp = payload;
    state.accountExpList = payload.expList;
  },
  SET_PROFILE_EXP(state, payload) {
    if (payload.expList && payload.expList.length) {
      const index = payload.expList.findIndex((item) => {
        return item.username === payload.username;
      });
      if (index !== -1) {
        state.profileAccountExp = payload.expList[index];
      } else {
        let updateUser = {
          username: payload.username,
          twitterUsername: state.profileTwitterUsername,
          userExp: 0,
        };
        state.profileAccountExp = updateUser;
      }
    } else {
      let updateUser = {
        username: payload.username,
        twitterUsername: state.profileTwitterUsername,
        userExp: 0,
      };
      state.profileAccountExp = updateUser;
    }
  },
  SET_ACCOUNT_GIVEAWAY(state, payload) {
    if (payload.cooldown) {
      state.accountGiveawaysCooldown = payload.cooldown;
    }
  },
  // CRUD DB SETTERS
  UPDATE_ACCOUNT_LAST_ACTIVE_TOURNEY_MONTH(state, payload) {
    state.accountLastActiveTourneyMonth = payload;
  },
  UPDATE_ACCOUNT_LAST_ACTIVE_TOURNEY_DATE(state, payload) {
    state.accountLastActiveTourneyDate = payload;
  },

  // FIRESTORE LOADERS

  ACCOUNTS_LOADED(state) {
    state.accountsLoaded = true;
  },
  ACCOUNT_LOADED(state) {
    state.accountLoaded = true;
  },
  ACCOUNT_TOURNEYS_LOADED(state) {
    state.accountTourneysLoaded = true;
  },

  ACCOUNT_EXP_LOADED(state) {
    state.accountExpLoaded = true;
  },
  ACCOUNT_RANKS_LOADED(state) {
    state.accountRanksLoaded = true;
  },
  ACCOUNT_TOURNEY_LOADED(state) {
    state.accountTourneyLoaded = true;
  },
};

const getters = {};

const actions = {
  async GET_ACCOUNT({ commit }, user) {
    try {
      // Assuming 'user' is the username which is unique and serves as a document ID
      const accountRef = db.collection('users').doc(user);
      const accountDoc = await accountRef.get();
      if (!accountDoc.exists) {
        // Redirect to home if account doesn't exist
        // Note: The check for routeName === 'tourney' should be moved to where this action is called if needed
        router.push({ name: 'tournaments' });
      } else {
        commit('SET_ACCOUNT', accountDoc);
        commit('ACCOUNT_LOADED');
      }
    } catch (error) {
      console.error('Error fetching account:', error);
      // Optionally handle the error here, e.g., by redirecting or showing an error message
    }
  },
  async GET_ACCOUNTS({ commit, state }) {
    const dataBase = await db.collection('accounts');
    const dbResults = await dataBase.get();
    const accounts = dbResults;

    accounts.forEach((doc) => {
      if (
        !state.accounts.some(
          (account) => account.userId === doc.data().userId
        ) &&
        doc.data().userId
      ) {
        const data = {
          userId: doc.data().userId,
          username: doc.data().username,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          userPhotoUrl: doc.data().userPhotoUrl,
          created_at: doc.data().created_at,
          email: doc.data().email,
          followers: doc.data().followers,
          mode: doc.data().mode,
          rank: doc.data().rank,
          referral: doc.data().referral,
          streamer: doc.data().streamer,
        };
        commit('SET_ACCOUNTS', data);
      }
    });
    commit('ACCOUNTS_LOADED');
  },

  async GET_ACCOUNT_RANKS({ commit }, userId) {
    const dataBase = await db.collection('ranks').doc(userId);
    const dbResults = await dataBase.get();
    commit('SET_ACCOUNT_RANKS', dbResults);
    commit('ACCOUNT_RANKS_LOADED');
  },
  async GET_ACCOUNT_TOURNEYS({ commit }, username) {
    try {
      const query = db
        .collection('tourneys')
        .where('username', '==', username)
        .where('isListed', '==', true)
        .where('isArchived', '==', false)
        .orderBy('created_at', 'desc')
        .limit(7);

      const querySnapshot = await query.get();

      if (!querySnapshot.empty) {
        const tourneys = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          docId: doc.id,
        }));
        commit('SET_ACCOUNT_TOURNEYS', tourneys);
        commit('ACCOUNT_TOURNEYS_LOADED');
      } else {
        console.log('No account tourneys found for user:', username);
      }
    } catch (error) {
      console.error('Error fetching account tourneys:', error);
    }
  },

  async GET_RTDB_MONTHLY_TOURNEY_BRIEF({ commit }, tourney) {
    return new Promise((resolve, reject) => {
      // Fetch data from the real-time database
      const getData = rtdb.ref('/tourneysBrief/' + tourney.docId);

      getData.once(
        'value',
        function (snapshot) {
          if (snapshot.exists()) {
            // Commit the data if it exists
            commit('SET_RTDB_MONTHLY_TOURNEY_BRIEF', snapshot);
          }

          // Commit mutation indicating that the RTDB tournament data has been loaded
          commit('RTDB_MONTHLY_TOURNEY_BRIEF_LOADED');

          // Resolve the promise once all actions are completed
          resolve();
        },
        reject
      ); // Reject the promise if there's an error during the fetch
    });
  },
  async GET_ACCOUNT_TOURNEY({ commit }, tourney) {
    let dataBase = await db.collection('tourneys').doc(tourney.docId);
    const dbResults = await dataBase.get();
    let results = dbResults.data();
    if (results) {
      commit('SET_ACCOUNT_TOURNEY', results);
    }
    commit('ACCOUNT_TOURNEY_LOADED');
  },

  async GET_ACCOUNT_MERCH({ commit, state }) {
    state.accountMerch = [];
    const dataBase = await db.collection('merch').doc(state.userId);
    const dbResults = await dataBase.get();
    const merch = dbResults;
    if (merch) {
      commit('SET_ACCOUNT_MERCH', merch);
    }
  },
  async GET_ACCOUNT_EXP(
    { commit, state },
    userId,
    profileTwitterUsername,
    username
  ) {
    state.accountExp = {};
    state.accountExpTweets = [];
    const dataBase = await db.collection('exp').doc(userId);
    const dbResults = await dataBase.get();
    const accountExp = dbResults.data();
    let completedSteps = 1;
    let pendingSteps = 1;
    if (accountExp && accountExp.tweets) {
      accountExp.tweets.forEach((doc) => {
        if (!state.accountExpTweets.some((tweet) => tweet.id === doc.id)) {
          if (
            doc.completedList &&
            doc.completedList.includes(profileTwitterUsername)
          ) {
            accountExp.step = completedSteps++;
            accountExp.currentStep = accountExp.step + 1;
            doc.stepCompleted = true;
            doc.stepStatus = 'Completed';
          } else if (
            (doc.pendingList &&
              doc.pendingList.includes(profileTwitterUsername)) ||
            (doc.requests && doc.requests.includes(profileTwitterUsername))
          ) {
            {
              accountExp.pendingCount = pendingSteps++;
              doc.stepCompleted = true;
              doc.currentStep = accountExp.step + 1;
              doc.stepStatus = 'Pending';
              doc.btnStatus = 'Pending';
            }
            accountExp.currentStep = accountExp.step + 1;
            doc.stepCompleted = true;
            doc.stepStatus = 'Pending';
          } else if (
            doc.pendingList &&
            !doc.pendingList.includes(profileTwitterUsername)
          ) {
            doc.stepCompleted = false;
            accountExp.currentStep = accountExp.step + 1;
            doc.stepStatus = 'Complete';
            doc.btnStatus = 'Complete';
          } else {
            doc.stepCompleted = false;
            doc.stepStatus = 'Complete';
          }
          doc.link =
            'https://www.twitter.com/' +
            state.account.twitterUsername +
            '/status/' +
            doc.id;
          if (accountExp.step === undefined) {
            accountExp.step = 0;
            accountExp.currentStep = 1;
          }
          commit('SET_ACCOUNT_EXP_TWEETS', doc);
          commit('SET_ACCOUNT_EXP', accountExp);
        }
      });
    }
    commit('SET_PROFILE_EXP', { accountExp, username });
    commit('ACCOUNT_EXP_LOADED');
  },

  // ACTIONS
  async ENTER_ACCOUNT_TOURNEY({ rootState, state }, data) {
    try {
      // Get the current user information from rootState
      let userId = rootState.UserStore.userId;
      let profileTourneysEntered = [
        ...rootState.UserStore.profileTourneysEntered,
      ]; // Clone the array
      // Ensure state.rtdbTourneyParticipants is updated and contains the participants
      const currentParticipants = state.rtdbTourneyParticipants;
      if (currentParticipants.length === 0) {
        console.error(
          'No participants found. The state may not be updated properly.'
        );
        return;
      }

      // Push the new participant to the participants list
      currentParticipants.push(data.enterRequest);

      // Update RTDB - /tourneys
      const rtdbRef = rtdb.ref(`/tourneys/${data.tourneyDocId}`);
      await rtdbRef.update({
        participants: currentParticipants,
        userId: data.enterRequest.userId,
      });

      // Update RTDB - /tourneysBrief
      const rtdbRef1 = rtdb.ref(`/tourneysBrief/${data.tourneyDocId}`);
      await rtdbRef1.update({
        participants: currentParticipants.length, // Update with the new length
        userId: data.enterRequest.userId,
      });

      // Update the profile tourneys entered in Firestore
      profileTourneysEntered.push(data.tourneyDocId);
      rootState.UserStore.profileTourneysEntered = profileTourneysEntered;
      await db.collection('accounts').doc(userId).update({
        tourneysEntered: profileTourneysEntered,
        userId: userId,
      });
    } catch (error) {
      console.error('Error entering the tourney:', error);
      // Optionally handle the error (e.g., show a message to the user)
    }
  },

  async UPDATE_ACCOUNT(account) {
    await db.collection('accounts').doc(account.userId).update(account);
  },

  // UNUSED

  async COPY_TOURNEYS() {
    try {
      const querySnapshot = await db.collection('tourneysFn').get();
      const copyPromises = querySnapshot.docs.map((doc) => {
        const tourneyData = doc.data();
        return db
          .collection('tourneys')
          .doc(doc.id)
          .set(tourneyData)
          .catch((error) => {
            console.error(`Error copying document ${doc.id}:`, error);
            // If you want to continue copying despite errors, you can return a resolved promise here
            return Promise.resolve(); // or throw the error if you want to stop the process
          });
      });

      await Promise.all(copyPromises);
    } catch (error) {
      console.error('Error in COPY_TOURNEYS:', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
