import { db, fb } from '@/firebase';

const state = {
  userData: {},
  userSubscription: null,
  userId: null,
  username: '',
  userCredits: null,
  userCoins: null,
  userInProgressTournaments: [],
  isUserParticipant: false,
  userPhotoUrl: null,
  profileAdmin: null,
  adminLevel: null,
  userEmail: null,
  userFirstName: null,
  profileLastName: null,
  profileInitials: null,
  profileCreated_at: null,
  profileSocials: null,
  profileDisplayName: null,
  profileDiscordUsername: null,
  profileFortniteUsername: null,
  profileTwitterUsername: null,
  profileChangedDisplayName: null,
  profileTwitterUid: null,
  profileTwitterStatus: null,
  profileSteamUsername: null,
  profileChangedSteamUsername: false,
  userPayments: [],

  profileAffiliate: null,
  profileSeniorAdmin: null,
  user: null,
  hiddenUserTourneys: [],
  hiddenUserTourneysLoaded: false,
  archivedUserTourneys: [],
  archivedUserTourneysLoaded: false,
  rtdbProfile: {},
  userLoaded: false,
  userCurrentStep: 1,
};

const mutations = {
  SET_USER(state, payload) {
    state.userData = payload.data();
    state.userCredits = payload.data().credits;
    state.userCoins = payload.data().coins;
    state.username = payload.data().username;
    state.userSubscription = payload.data().subscription;
    state.userInProgressTournaments = payload.data().inProgressTournaments;
    state.userId = payload.data().userId;
    state.userEmail = payload.data().email;
    state.profileDisplayName = payload.data().displayName;
    state.profileAdmin = payload.data().admin;
    state.profileFortniteUsername = payload.data().fortniteUsername;
    state.profileDiscordUsername = payload.data().discordUsername;
    state.profileTwitterUsername = payload.data().twitterUsername;
    state.profileTwitterStatus = payload.data().twitterStatus;
    state.userPhotoUrl = payload.data().photoUrl;
    state.profileCreated_at = payload.data().created_at;
    state.profileSocials = payload.data().socials;

    if (payload.data().affiliate) {
      state.profileAffiliate = payload.data().affiliate;
    }
  },
  REMOVE_TOURNEY(state, tourneyId) {
    state.tournaments = state.tournaments.filter((t) => t.id !== tourneyId);
  },
  UPDATE_TOURNEY_LISTING(state, tourney) {
    const index = state.tournaments.findIndex((t) => t.id === tourney.id);
    if (index !== -1) {
      state.tournaments.splice(index, 1, tourney);
    }
  },
  UPDATE_DISCORD_USERNAME(state, payload) {
    state.profileDiscordUsername = payload;
  },
  UPDATE_FORTNITE_USERNAME(state, payload) {
    state.profileFortniteUsername = payload;
  },
  UPDATE_DISPLAY_NAME(state, payload) {
    state.profileDisplayName = payload;
    state.profileChangedDisplayName = true;
  },
  SET_USER_ADMIN(state) {
    state.profileAdmin = true;
  },
  SET_USER_ADMIN_SENIOR(state) {
    state.profileSeniorAdmin = true;
  },
  SET_USER_INITIALS(state) {
    if (state.userFirstName && state.profileLastName) {
      state.profileInitials =
        state.userFirstName.match(/(\b\S)?/g).join('') +
        state.profileLastName.match(/(\b\S)?/g).join('');
    }
  },

  SET_PROFILE_PHOTO(state, payload) {
    state.userPhotoUrl = payload;
  },
  UPDATE_PROFILE_STEAM_USERNAME(state, payload) {
    state.profileSteamUsername = payload;
    state.profileChangedSteamUsername = true;
  },
  UPDATE_USER(state, payload) {
    state.user = payload;
  },
  SET_HIDDEN_USER_TOURNEYS(state, hiddenTourneys) {
    state.hiddenUserTourneys = hiddenTourneys;
  },
  HIDDEN_USER_TOURNEYS_LOADED(state) {
    state.hiddenUserTourneysLoaded = true;
  },
  SET_ARCHIVED_USER_TOURNEYS(state, archivedTourneys) {
    state.archivedUserTourneys = archivedTourneys;
  },
  ARCHIVED_USER_TOURNEYS_LOADED(state) {
    state.archivedUserTourneysLoaded = true;
  },

  USER_LOADED(state) {
    state.userLoaded = true;
  },
  // Setters
  SET_PROFILE_USERNAME(state, newUsername) {
    state.username = newUsername;
  },
  SET_USER_PAYMENTS_CREDITS(state, payload) {
    state.userPayments.push(payload);
  },
  SET_USER_PARTICIPANT(state, value) {
    state.isUserParticipant = value;
  },
  SET_USER_IN_PROGRESS_TOURNAMENTS(state, payload) {
    const index = state.userInProgressTournaments.findIndex(
      (item) => item === payload
    );
    if (index === -1) {
      // If payload is not in the array, add it
      state.userInProgressTournaments.push(payload);
    } else {
      // If payload is already in the array, remove it
      state.userInProgressTournaments.splice(index, 1);
    }
  },
  SET_USER_CURRENT_STEP(state, value) {
    state.userCurrentStep = value;
  },
  DECREASE_USER_CREDITS(state, value) {
    // Ensure that the state.userCredits is not null or undefined
    if (state.userCredits !== null && state.userCredits !== undefined) {
      // Check if the subtraction would result in a negative number, if so, set to zero
      state.userCredits = Math.max(state.userCredits - value, 0);
    }
  },
  INCREASE_USER_CREDITS(state, value) {
    // Ensure that the state.userCredits is not null or undefined
    if (state.userCredits !== null && state.userCredits !== undefined) {
      state.userCredits = Math.max(state.userCredits + value, 0);
    }
  },
};

const getters = {};

const actions = {
  async SET_AUTH_STATE_LISTENER({ commit, dispatch }) {
    fb.auth().onAuthStateChanged(async (user) => {
      try {
        commit('UPDATE_USER', user);
        if (user) {
          await dispatch('GET_USER', user);
          await dispatch('CHECK_USER_COINS', user);
        } else {
          commit('CLEAR_USER_DATA');
        }
      } catch (error) {
        console.error('Error in auth state listener:', error);
        // Handle error appropriately, maybe commit an error state
      }
    });
  },

  async GET_USER({ commit }, user) {
    const dataBase = await db.collection('users').doc(user.displayName);
    const dbResults = await dataBase.get();
    if (dbResults.exists) {
      commit('SET_USER', dbResults);
      commit('USER_LOADED');
      commit('SET_USER_INITIALS');

      if (dbResults.data().admin && dbResults.data().adminLevel === 'Mod') {
        commit('SET_USER_ADMIN');
      } else if (
        dbResults.data().admin &&
        dbResults.data().adminLevel === 'Manager'
      ) {
        commit('SET_USER_ADMIN_SENIOR');
      }
    }
  },

  async UPLOAD_USER_PHOTO({ commit, state }, options) {
    let file = options.photo;
    let storageRef = fb.storage().ref('/' + options.folder + '/' + file.name);
    let uploadTask = storageRef.put(file);
    uploadTask.on(
      'state_changed',
      // eslint-disable-next-line
      (snapshot) => {},
      // eslint-disable-next-line
      (error) => {},
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          commit('SET_PROFILE_PHOTO', downloadURL);
          let accountPhoto = {
            photoUrl: downloadURL,
            userId: state.userId,
          };
          db.collection('users').doc(state.username).update(accountPhoto);
        });
      }
    );
  },
  async CHECK_USER_COINS({ commit, state }, user) {
    state.userPayments = [];
    const parentDocRef = db.collection('clients').doc(user.uid);
    const paymentsRef = parentDocRef.collection('payments');
    paymentsRef
      .where('status', '==', 'succeeded')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (
            !state.userPayments.some((payment) => payment.id === doc.data().id)
          ) {
            const data = {
              id: doc.id,
              charges: doc.data().charges,
            };

            if (data.charges) {
              commit('SET_USER_PAYMENTS_CREDITS', data);

              let price = doc.data().amount_received;
              if (price === 2044) {
                state.userCredits = state.userCredits + 2000;
              } else if (price === 1533) {
                state.userCredits = state.userCredits + 1500;
              } else if (price === 1044) {
                state.userCredits = state.userCredits + 1000;
              } else if (price === 544) {
                state.userCredits = state.userCredits + 500;
              } else if (price === 133) {
                state.userCredits = state.userCredits + 100;
              }
              const userRef = db.collection('users').doc(state.username);
              userRef.update({
                credits: state.userCredits,
                userId: state.username,
              });

              // Update the payment status in the 'payments' subcollection
              const subDocRef = paymentsRef.doc(doc.id);
              subDocRef.update({
                status: 'finished',
              });
            } else if (!data.charges) {
              const subDocRef = paymentsRef.doc(doc.id);
              subDocRef.update({
                status: 'finished',
                username: user.username,
              });
            }
          }
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
