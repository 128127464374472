import Vue from 'vue';
import VueRouter from 'vue-router';
import { fb, db, auth } from '@/firebase';
import store from '@/_store/_MainStore';

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'tournaments',
    component: () => import('../_views/tournaments/_TournamentsView.vue'),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      requiresEmailVerified: false,
    },
  },
  {
    path: '/required-info/',
    name: 'required-info',
    component: () => import('../_views/auth/_RequiredInfoView.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      requiresEmailVerified: false,
    },
  },
  {
    path: '/browse/',
    name: 'browse',
    component: () => import('../_views/browse/_FeedView.vue'),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      requiresEmailVerified: false,
    },
  },
  {
    path: '/giveaway/:id',
    name: 'giveaway',
    component: () =>
      import('../_views/_profile/giveawaysProfile/_WinnersGiveawayView.vue'),
    meta: {
      requiresAdmin: false,
      guest: false,
      requiresEmailVerified: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => import('../_views/auth/_ForgotPassword.vue'),
    beforeEnter(to, from, next) {
      const user = fb.auth().currentUser;
      if (user) {
        next('/');
      } else {
        next();
      }
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../_views/auth/_LoginView.vue'),
    meta: { requiresAuth: false, guest: true },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../_views/auth/_LogoutView.vue'),
    beforeEnter: async (to, from, next) => {
      try {
        await fb.auth().signOut();
        next('/');
      } catch (error) {
        console.error('Error signing out:', error);
        next('/');
      }
    },
  },

  {
    path: '/article/:id',
    name: 'ArticleView',
    component: () =>
      import('../_views/others/articles/article/_ArticleBlogView.vue'),
    meta: { requiresAuth: false },
  },

  {
    path: '/team/',
    name: 'team',
    component: () => import('../_views/others/team/_TeamView.vue'),
    meta: { requiresAuth: false, requiresAdmin: false },
  },
  {
    path: '/about/',
    name: 'about',
    component: () => import('../_views/others/about/AboutView.vue'),
    meta: { requiresAuth: false, requiresAdmin: false },
  },
  {
    path: '/admin/',
    name: 'admin',
    component: () => import('../_views/admin/_AdminView.vue'),
    meta: {
      requiresAuth: true,
      requiresEmailVerified: true,
    },
  },

  {
    path: '/giveaways/',
    name: 'giveaways',
    component: () => import('../_views/giveaways/_GiveawaysView.vue'),
    meta: { requiresAdmin: false, requiresEmailVerified: false },
  },

  {
    path: '/leaderboards/',
    name: 'leaderboards',
    component: () => import('../_views/leaderboards/_LeaderboardsView.vue'),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
    },
  },

  {
    path: '/ranks/',
    name: 'ranks',
    component: () => import('../_views/ranks/_GetRankView.vue'),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      requiresEmailVerified: true,
    },
  },

  {
    path: '/store/',
    name: 'store',
    component: () => import('../_views/store/_CoinsView.vue'),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      requiresEmailVerified: true,
    },
  },
  {
    path: '/successful-transaction/',
    name: 'successfulTransaction',
    component: () =>
      import('../_views/store/payments/_SuccessfulTransactionView.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      requiresEmailVerified: true,
    },
  },
  {
    path: '/failed-transaction/',
    name: 'failedTransaction',
    component: () =>
      import('../_views/store/payments/_FailedTransactionView.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      requiresEmailVerified: true,
    },
  },

  {
    path: '/settings/',
    name: 'settings',
    component: () => import('../_views/others/settings/_SettingsView.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      requiresEmailVerified: true,
    },
  },

  {
    path: '/:id',
    name: 'account',
    component: () => import('../_views/_account/_AccountView.vue'),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      guest: false,
      requiresEmailVerified: false,
    },
  },
  {
    path: '/:id/bets/:id',
    name: 'bets',
    component: () => import('../_views/bets/_BetsView.vue'),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      guest: false,
      requiresEmailVerified: false,
    },
  },
  {
    path: '/u/:id',
    name: 'profile',
    component: () => import('../_views/_profile/_ProfileView.vue'),
    meta: {
      requiresEmailVerified: true,
      requiresAuth: true,
      requiresSameUsername: true,
      requiresAdmin: false,
      guest: false,
    },
  },

  {
    path: '/:id/leaderboard',
    name: 'leaderboard',
    component: () =>
      import('../_views/_profile/leaderboard/_LeaderboardView.vue'),
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
      guest: false,
      requiresEmailVerified: true,
    },
  },

  {
    path: '/verify-email',
    name: 'verifyEmail',
    component: () => import('../_components/others/VerifyEmail.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      guest: false,
      requiresEmailVerified: false,
    },
  },
  {
    path: '/others/legal/privacy-policy',
    name: 'privacyPolicy',
    component: () => import('../_views/others/legal/PrivacyPolicy.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      guest: false,
      requiresEmailVerified: false,
    },
  },
  {
    path: '/others/legal/terms-and-conditions',
    name: 'terminAndConditions',
    component: () => import('../_views/others/legal/Terms&Conditions.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      guest: false,
      requiresEmailVerified: false,
    },
  },
  {
    path: '*',
    component: () => import('../_components/others/PageNotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  // Changed to async function
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresEmailVerified = to.matched.some(
    (record) => record.meta.requiresEmailVerified
  );
  const requiresGuest = to.matched.some((record) => record.meta.guest);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  const requiresSameUsername = to.matched.some(
    (record) => record.meta.requiresSameUsername
  ); // New condition
  let user = auth.currentUser; // Get current user from auth

  if (!user) {
    // Wait for the authentication state to change
    const unsubscribe = auth.onAuthStateChanged((newUser) => {
      unsubscribe(); // Unsubscribe to prevent memory leaks
      user = newUser;

      checkUser(user);
    });
  } else {
    checkUser(user);
  }

  async function checkUser(user) {
    let admin = false;
    let hasUsername = false;
    let hasBirthday = false;
    let usernameMatch = false; // New variable to track username match

    if (user) {
      const token = await user.getIdTokenResult();
      admin = !!token.claims.admin;

      // Use fb object to access collections
      const userDoc = await db.collection('users').doc(user.displayName).get();
      if (userDoc.exists) {
        const userData = userDoc.data();
        hasUsername = !!userData.username;
        hasBirthday = !!userData.birthday;

        if ((!hasUsername && to.name !== 'required-info') || !hasBirthday) {
          return next({ name: 'required-info' });
        }
        // Check if the route requires the same username as logged in user
        if (requiresSameUsername) {
          // Assuming the username is passed as a route parameter named 'username'
          const routeUsername = to.params.id;
          usernameMatch = userData.username === routeUsername;
        }
      }
    }

    if (requiresGuest && user) {
      return next('/');
    }

    if (user && requiresEmailVerified && !user.emailVerified) {
      return next({ name: 'VerifyEmail' });
    }

    if (requiresAuth && !user) {
      return next({ name: 'Login' });
    }

    if (requiresAuth && requiresAdmin && !admin) {
      return next('/');
    }

    if (requiresSameUsername && !usernameMatch) {
      // Redirect if the usernames do not match
      return next({ name: 'account', params: { id: to.params.id } });
    }

    next();
  }

  store.commit('SET_ROUTE', to);
  store.commit('ROUTE_LOADED', true);
  next();
});

export default router;
