<template>
  <footer>
    <p class="pt-2">
      © {{ new Date().getFullYear() }} VFORCE.GG - All rights reserved.
    </p>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

<style scoped>
footer {
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 1rem;
}
</style>
