<template>
  <v-toolbar-items v-if="user && userLoaded">
    <v-menu v-model="menu" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-btn
          color="transparent"
          @click="show = !show"
          v-on="on"
          class="pb-0 pl-2 pr-0 text--primary hover-effect"
          elevation="0"
          aria-label="Toggle user profile"
        >
          <v-avatar size="40" class="mr-2 white pl-0" v-if="userPhotoUrl">
            <v-img
              cover
              :src="userPhotoUrl"
              :lazy-src="userPhotoUrl || '/assets/default-avatar.jpg'"
              alt="Profile picture of the user"
            />
          </v-avatar>
          <v-avatar size="40" class="mr-2 white pl-0" v-else>
            <v-icon>mdi-account</v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-list class="mt-1 pa-0" dark>
        <v-list-item-group>
          <v-list-item disabled>
            <v-container grid-list-xs class="pl-0">
              <v-row class="pb-1">
                <v-col cols="3" class="py-2 mt-1">
                  <v-avatar size="30" class="white" v-if="userPhotoUrl">
                    <v-img
                      contain
                      :src="userPhotoUrl"
                      :lazy-src="userPhotoUrl"
                      alt="User avatar"
                    />
                  </v-avatar>
                  <v-avatar size="30" class="mr-2 white pl-0" v-else>
                    <v-icon color="black">mdi-account</v-icon>
                  </v-avatar>
                </v-col>
                <v-col cols="9" class="py-2">
                  <h5
                    class="font-weight-black white--text ml-1 mt-2 text-uppercase mb-0"
                  >
                    {{ username }}
                  </h5>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item>
          <v-divider></v-divider>
          <div
            v-for="(item, i) in dropDownNavList"
            :key="'A' + i"
            v-show="shouldShowItem(item)"
          >
            <v-list-item active-class="" :to="item.route" @click="menu = false">
              <v-list-item-title class="white--text font-weight-bold">
                <v-icon left color="white">{{ item.icon }}</v-icon>
                <span style="font-size: 14px">{{ item.text }}</span>
              </v-list-item-title>
            </v-list-item>
            <v-divider v-if="item.divider"></v-divider>
          </div>

          <v-list-item class="pointer" @click="logout(), (menu = false)">
            <v-list-item-title class="white--text font-weight-bold">
              <v-icon left color="error">mdi-logout-variant</v-icon>
              <span style="font-size: 14px">Logout</span>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-toolbar-items>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import { db, fb } from '@/firebase';

export default {
  name: 'DropdownComponent',
  data() {
    return {
      show: false,
      menu: false,
      menu1: false,
      fav: true,
    };
  },
  computed: {
    ...mapState(['liveStreamer', 'subscription', 'adminLevel']),
    ...mapState('UserStore', [
      'user',
      'username',
      'userPhotoUrl',
      'profileAccountType',
      'userId',
      'userEmail',
      'userFirstName',
      'profileLastName',
      'profileType',
      'profileInitials',
      'profileAdmin',
      'profileState',
      'profileTotalExp',
      'profileLevel',
      'userLoaded',

      'rtdbProfileCredits',
      'profileSubscription',
      'profileSeniorAdmin',
    ]),
    ...mapGetters(['profileMode']),
    dropDownNavList() {
      return [
        {
          text: 'Channel',
          type: 'route',
          route: `/${this.username}`,
          icon: 'mdi-account',
        },
        {
          text: 'Settings',
          type: 'route',
          route: '/settings',
          icon: 'mdi-cog',
          divider: true,
        },

        {
          text: 'Analytics',
          route: '/admin#analytics',
          icon: 'mdi-account-arrow-right-outline',
          requiredRole: 'profileSeniorAdmin',
        },
        {
          text: 'Users',
          route: '/admin#users',
          icon: 'mdi-account-arrow-right-outline',
          requiredRole: 'profileAdmin',
        },
        {
          text: 'Ranks',
          route: '/admin#ranks',
          icon: 'mdi-account-arrow-right-outline',
          requiredRole: 'profileAdmin',
        },
        {
          text: 'Tourneys',
          route: '/admin#tourneys',
          icon: 'mdi-account-arrow-right-outline',
          requiredRole: 'profileSeniorAdmin',
        },
        {
          text: 'Merch',
          route: '/admin#merch',
          icon: 'mdi-account-arrow-right-outline',
          requiredRole: 'profileSeniorAdmin',
        },
        {
          text: 'Team',
          route: '/admin#team',
          icon: 'mdi-account-arrow-right-outline',
          requiredRole: 'profileAdmin',
        },
        {
          text: 'Manage',
          route: '/admin#manage',
          icon: 'mdi-account-arrow-right-outline',
          requiredRole: 'profileSeniorAdmin',
          divider: true,
        },
      ];
    },
    mode: {
      get() {
        return this.profileMode;
      },
      set(newName) {
        return newName;
      },
    },
    profileLevel() {
      return Math.trunc(this.profileTotalExp / 100);
    },
    profileExpProgressBar: {
      get() {
        let number = this.profileTotalExp / 100;
        let getDecimalVal = number.toString().indexOf('.');
        let decimalPart = number.toString().substring(getDecimalVal + 1);
        if (decimalPart.length === 1) {
          decimalPart = decimalPart.concat('0');
        }
        return +decimalPart;
      },
      set(newValue) {
        this.$store.commit('UPDATE_PROFILE_TOTAL_EXP', newValue);
      },
    },
  },

  methods: {
    ...mapActions('AccountStore', ['GET_ACCOUNT']),
    shouldShowItem(item) {
      // Show item if user has the required role
      if (item.requiredRole === 'profileSeniorAdmin') {
        return this.profileSeniorAdmin;
      } else if (item.requiredRole === 'profileAdmin') {
        return this.profileAdmin || this.profileSeniorAdmin;
      }
      // Default to showing the item if no specific role required
      return true;
    },

    toggleProfileMode(mode) {
      let newMode = !mode;
      let update = {
        mode: newMode,
      };
      db.collection('accounts').doc(this.userId).update(update);
      this.$store.commit('UPDATE_PROFILE_MODE', newMode);
    },

    logout() {
      fb.auth()
        .signOut()
        .then(() => {
          this.$router.replace('/');
        });
    },
  },
};
</script>
<style scoped>
.no-hover {
  pointer-events: auto; /* Allow click events */
}

.no-hover:hover,
.no-hover.router-link-active {
  background-color: transparent !important;
  color: inherit !important; /* This ensures text color doesn't change */
  cursor: pointer;
}

.no-hover.router-link-active .v-list-item__title,
.no-hover.router-link-active .v-icon {
  color: white !important; /* If icons or text color should remain white */
}
:deep(.v-badge--bordered .v-badge__badge) {
  min-width: 15px;
  height: 15px;
  border-radius: 15px;
}
.expPadTop {
  padding-top: 2px;
}
.expMarLeft {
  margin-left: 3px;
}
.xsWidth {
  width: 60px;
}
.mdWidth {
  width: 145px;
}
</style>
