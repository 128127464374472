import { rtdb } from '@/firebase'; // Adjust the import path as necessary

const state = {
  accountRtdbTournaments: [],
  accountRtdbTournamentsLoaded: false,
  rtdbTournamentParticipants: [],
  rtdbTournamentLoaded: false,
};

const mutations = {
  SET_ACCOUNT_RTDB_TOURNAMENT_PARTICIPANTS(state, payload) {
    state.rtdbTournamentParticipants = payload;
  },
  ACCOUNT_RTDB_TOURNAMENT_PARTICIPANTS_LOADED(state) {
    state.rtdbTournamentParticipantsLoaded = true;
  },
  SET_ACCOUNT_RTDB_TOURNAMENTS(state, payload) {
    state.accountRtdbTournaments = payload;
  },
  CLEAR_ACCOUNT_RTDB_TOURNAMENTS(state) {
    // Assuming tournaments are stored in state under 'tournaments'
    state.accountRtdbTournaments = [];
    // Or if it's an object:
    // state.tournaments = {};
  },
  ACCOUNT_RTDB_TOURNAMENTS_LOADED(state) {
    state.accountRtdbTournamentsLoaded = true;
  },
  UPDATE_ACCOUNT_RTDB_TOURNAMENT(state, payload) {
    const tournamentIndex = state.accountRtdbTournaments.findIndex(
      (tournament) => tournament.docId === payload.docId
    );

    if (tournamentIndex !== -1) {
      // Update the tournament at the found index with the new data from payload
      state.accountRtdbTournaments[tournamentIndex].participantsCount =
        payload.participantsCount;
    } else {
      console.error('Tournament not found with docId:', payload.docId);
    }
  },
};

const actions = {
  async GET_ACCOUNT_RTDB_TOURNAMENT_PARTICIPANTS({ commit }, tournamentDocId) {
    try {
      let getData = await rtdb.ref(
        '/tournamentsParticipants/' + tournamentDocId
      );
      const snapshot = await getData.once('value');

      if (snapshot.exists()) {
        let data = snapshot.val().participantsList;

        commit('SET_ACCOUNT_RTDB_TOURNAMENT_PARTICIPANTS', data);
      }

      commit('ACCOUNT_RTDB_TOURNAMENT_PARTICIPANTS_LOADED');
    } catch (error) {
      console.error('Error fetching tournament data from RTDB:', error);
      // Optionally, you might want to handle this error state in your UI or store
    }
  },
  async GET_ACCOUNT_RTDB_TOURNAMENTS({ commit }, username) {
    try {
      commit('CLEAR_ACCOUNT_RTDB_TOURNAMENTS');
      // Assuming 'tournaments' is the path in RTDB where tournaments are stored
      const ref = rtdb
        .ref('tournamentsBrief')
        .orderByChild('username')
        .equalTo(username);
      const snapshot = await ref.once('value');

      if (snapshot.exists()) {
        let tournaments = [];
        snapshot.forEach((childSnapshot) => {
          const tournament = childSnapshot.val();
          if (tournament.isListed && !tournament.isArchived) {
            tournaments.push({
              ...tournament,
              docId: childSnapshot.key, // RTDB uses 'key' instead of 'id'
            });
          }
        });

        // Sort tournaments by 'created_at' in descending order
        tournaments.sort((a, b) => (b.created_at || 0) - (a.created_at || 0));

        // Limit to 8 tournaments
        tournaments = tournaments.slice(0, 8);
        commit('SET_ACCOUNT_RTDB_TOURNAMENTS', tournaments);
        commit('ACCOUNT_RTDB_TOURNAMENTS_LOADED');
      } else {
        commit('ACCOUNT_RTDB_TOURNAMENTS_LOADED');
      }
    } catch (error) {
      console.error('Error fetching account tourneys from RTDB:', error);
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
